import { AUTH_REFRESH, logout } from '@/api/auth';
import { useStore, useTemporaryStore } from '@/store';
import { resetRegisteredState } from '@/views/client/SignUp/helpers';
import { coloredLog } from '@/utils/coloredLog';
import { useNotifications } from '@/composables/useNotifications';
import { useToken } from '@/composables/useToken';
import { SERVICE_PAGES } from '@/common/data';
import { checkRequest, updateAppVersion } from '@/utils/functions';

import { cacheRequest, clearResponseByUrl, fillCachedResponse } from './cachedDataStorage';
import { debouncedRefreshTokensHandler, getTimezoneOffset, needsToBeCached } from './helpers';
import { instance } from './index';

export const AUTH_ENDPOINTS = [
  '/api/merchants/login',
  '/api/admin/login',
];

export const requestHandler = (axiosRequest) => {
  axiosRequest.headers.gmt_offset = getTimezoneOffset();
  axiosRequest.headers.date_time = new Date().toISOString();

  if (axiosRequest?.data) {
    axiosRequest.data = checkRequest(axiosRequest.data);
  }

  /*
    TODO
    temporary disabled because of cached service on backend side
    REF: https://linear.app/frl/issue/SWI-4597#comment-2bf35965

  */
  if (axiosRequest?.params?.shouldResetRequestState) {
    clearResponseByUrl(axiosRequest.url);
    delete axiosRequest.params.shouldResetRequestState;
  }

  if (axiosRequest?.params?.isFreshResponse) {
    coloredLog('GET FRESH RESPONSE');
    delete axiosRequest.params.isFreshResponse;
    return axiosRequest;
  }

  if (axiosRequest?.data?.isFreshResponse) {
    delete axiosRequest.data.isFreshResponse;
    return axiosRequest;
  }

  if (axiosRequest?.params?.isFreshResponse === false) {
    delete axiosRequest.params.isFreshResponse;
  }

  const isCacheResponse = needsToBeCached(axiosRequest) || axiosRequest?.params?.isFreshResponse === false;
  if (isCacheResponse) {
    const { request, cashedResponse, hasCashedResponse } = cacheRequest(axiosRequest);
    if (hasCashedResponse) {
      coloredLog(`HAS CACHED RESPONSE: ${request.url}`);
      // eslint-disable-next-line
      throw { hasCashedResponse, cashedResponse };
    }
    return request;
  }
  return axiosRequest;
};

const updateLastResponse = (code, message, version) => {
  const store = useStore();

  // TODO ask devops to return CASE error message
  // UDP we asked Dima, but he answered that it's impossible to update it
  const TOO_MANY_REQUESTS = 'Too many requests, please try again later.';

  const statusText = code === 429 && message === TOO_MANY_REQUESTS ? 'TOO_MANY_REQUESTS' : message;

  updateAppVersion(version);

  store.$patch({
    lastResponse: {
      status: code,
      statusText,
      isServicePage: Object.prototype.hasOwnProperty.call(SERVICE_PAGES, statusText),
    },
  });
};

export const requestErrorHandler = (error) => (error?.hasCashedResponse
  ? Promise.resolve(error)
  : Promise.reject(error));

export const responseHandler = (response) => {
  if (response.config?.url !== '/settings/admin/signup') {
    updateLastResponse(response?.status, response?.statusText, response.headers.feversion);
  }

  const accessToken = response?.data?.data?.access?.token;
  const refreshToken = response?.data?.data?.refresh?.token;

  if (accessToken) {
    const { updateTokenInStorage } = useToken();
    updateTokenInStorage({ accessToken, refreshToken });
  }

  if (needsToBeCached(response.config)) {
    fillCachedResponse(response.config, response);
  }

  // TODO REMOVE AFTER REFRESH_TOKEN FUNCTIONALLITY IS FIXED
  if (accessToken) {
    const { store: tempStore } = useTemporaryStore();
    tempStore.$patch({
      tokens: [...tempStore.tokens, { accessToken, refreshToken }],
    });
  }
  return response;
};

export const errorResponseHandler = async (error) => {
  updateLastResponse(error?.response?.status, error?.response?.data?.message || error?.response?.data);

  if (error?.hasCashedResponse) {
    return Promise.resolve(error?.cashedResponse);
  }

  const { addNotification, t } = useNotifications();
  const isAuthEndpoint = AUTH_ENDPOINTS.includes(error.response?.data?.path);

  if (error.response?.status === 401) {
    const tokenHasBeenExpiredOnRefreshEndpoint = error.response.data.path === `/api${AUTH_REFRESH}` && error.response.data.message === 'TokenExpiredError';
    try {
      if ('JsonWebTokenError'.includes(error.response.data.message)) {
        addNotification({ text: t('merchantDashboard.notification.sessionWasExpired'), config: { color: 'error' } });
        throw new Error('SESSION WAS EXPIRED');
      }

      if (error.response.data.message === 'Unauthorized') {
        throw new Error('SESSION NOT AUTHORIZED');
      }

      if (error.response.data.message === 'INVALID_SESSION') {
        addNotification({ text: t('errors.INVALID_SESSION'), config: { color: 'error' } });
        throw new Error('DEVICE NOT FOUND');
      }

      if (error.response.config?.hasBeenRefetched) {
        throw new Error('STOP INFINITE CALLS. SESSION WAS NOT UPDATED');
      }

      if (tokenHasBeenExpiredOnRefreshEndpoint) {
        throw new Error('SESSION WAS NOT UPDATED');
      }
      if (error.response.data.message === 'INVALID_SESSION_TOKEN') {
        logout({ pushToHome: true, useLogoutEndpoint: false });
        throw error;
      }

      const { tokenRole: role } = useToken();
      const isClient = role.value === 'client';
      if (isClient) {
        logout({ pushToHome: true, useLogoutEndpoint: false });
        throw error;
      }

      if (!isAuthEndpoint) {
        const isSuccess = await debouncedRefreshTokensHandler();
        if (isSuccess) {
          const { authToken } = useToken();
          error.response.config.headers.Authorization = `Bearer ${authToken.value}`;

          coloredLog('RE-FETCH DATA');
          return instance({
            ...error.response.config,
            hasBeenRefetched: true,
          });
        }
        throw new Error('SESSION_WAS_NOT_UPDATED');
      }
    } catch (errorMessage) {
      if (typeof errorMessage === 'string') {
        coloredLog(errorMessage, '#cc0000');
      }
      if (error.response.config.headers.useLogoutHandlerWhenError) {
        const errorTypes = ['TokenExpiredError', 'INVALID_SESSION', 'JsonWebTokenError', 'Unauthorized'];
        const useLogoutEndpoint = !(error.response.status === 401 && errorTypes.includes(error.response.data.message));
        logout({
          pushToHome: true,
          useLogoutEndpoint,
        });
      }
      throw error;
    }
  }

  const isRefreshErrorForUnregisteredUser = error.response?.status === 404 && error.response?.data?.message === 'UNREGISTERED_USER_NOT_FOUND';
  if (isRefreshErrorForUnregisteredUser) {
    resetRegisteredState();
  }

  // if (error.response?.status === 404) {
  //   error.response.data.message = 'Code 404. Wrong endpoint';
  // }

  return Promise.reject(error);
};

window.getTokensList = () => {
  const { store: tempStore } = useTemporaryStore();

  return tempStore.tokens;
};

window.clearTokensList = () => {
  const { store: tempStore } = useTemporaryStore();
  tempStore.$patch({
    tokens: [],
  });

  return tempStore.tokens;
};
