import { reactive } from 'vue';

import { i18n } from '@/plugins/localization';

const notifId = 0;
const notifications = reactive([]);
export function useNotifications() {
  const addNotification = ({
    title, text, config, hasCloseButton,
  }) => {
    const defaultConfig = {
      app: true,
      duration: 2000,
      top: true,
      color: 'success',
    };

    // notifications.splice(notifications.length - 1, 1);
    //
    // setTimeout(() => {
    //   notifications.push({
    //     text,
    //     id: notifId,
    //     hasCloseButton,
    //     isShown: true,
    //     config: {
    //       ...defaultConfig,
    //       ...config,
    //     },
    //   });
    //   notifId++;
    // }, 0);

    notifications[0] = {
      title,
      text,
      id: notifId,
      hasCloseButton,
      isShown: true,
      config: {
        ...defaultConfig,
        ...config,
      },
    };
  };
  const removeNotification = (id) => {
    const indexFordel = notifications.findIndex((el) => el.id === id);
    notifications.splice(indexFordel, 1);
  };
  const { t } = i18n.global;

  return {
    notifications, addNotification, removeNotification, t,
  };
}

// addNotification({ text: message, config: { color: 'error' } });

export default useNotifications;
