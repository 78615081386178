import { emitter } from '@/composables/useBus';

emitter.on('update-page-data', () => {
  clearCachedResponses();
});

const convertRegiestToString = ({ method, params, url }) => {
  const stringParams = params && Object.entries(params)
    .flatMap((param) => {
      const [key, value] = param;
      if (value === null) {
        return [];
      }
      return `${key}=${value}`;
    })
    .join('&');

  return `${method}:${url}?${stringParams || ''}`;
};

export const cachedResponses = {};

window.cachedResponses = cachedResponses;

export const cacheRequest = (request) => {
  const requestedString = convertRegiestToString(request);
  if (!requestedString) {
    return { request };
  }
  const cashedResponse = cachedResponses[requestedString];

  if (!cashedResponse) {
    cachedResponses[requestedString] = {};
  }
  const hasCashedResponse = cashedResponse && Boolean(Object.keys(cashedResponse).length);
  return { request, hasCashedResponse, cashedResponse };
};

export const fillCachedResponse = (request, response) => {
  const isRequestObjectHasParams = request && Boolean(Object.keys(request).length);
  if (isRequestObjectHasParams) {
    const requestedString = convertRegiestToString(request);
    cachedResponses[requestedString] = response;
  }
};

export const clearCachedResponses = () => {
  Object.keys(cachedResponses).forEach((prop) => {
    delete cachedResponses[prop];
  });
};

export const clearResponseByUrl = (url) => {
  try {
    Object.keys(cachedResponses).forEach((cachedUrl) => {
      const comparableURL = cachedUrl.split('?')[0].split('get:')[1];
      if (comparableURL === url) {
        delete cachedResponses[cachedUrl];
      }
    });
  } catch (error) {
    console.warn('wWARN: clearResponseByUrl issue');
  }
};
