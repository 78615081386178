<template>
  <component
    :is="variant"
    class="text"
    :class="{
      ['text__' + type]: type,
      'text__uppercase-first': uppercaseFirstLetter,
       ...classes,
      'is-block': isBlock,
    }"
  >
    <template v-if="to.length">
      <router-link :to="to">
        <slot />
      </router-link>
    </template>
    <template v-else>
      <slot />
    </template>
  </component>
</template>

<script>
import { computed } from 'vue';
import colors from '@/styles/colors';

export default {
  name: 'AppText',
  inheritAttrs: true,
  props: {
    variant: {
      type: String,
      default: 'span',
    },
    type: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '13px',
    },
    weight: {
      type: String,
      default: 'inherit',
    },
    lineHeight: {
      type: Number,
      default: 1.2,
    },
    mt: {
      type: String,
      default: '0',
    },
    mr: {
      type: String,
      default: '0',
    },
    mb: {
      type: String,
      default: '0',
    },
    ml: {
      type: String,
      default: '0',
    },
    pt: {
      type: String,
      default: '0',
    },
    pr: {
      type: String,
      default: '0',
    },
    pb: {
      type: String,
      default: '0',
    },
    pl: {
      type: String,
      default: '0',
    },
    color: {
      type: String,
      default: 'inherit',
    },
    theme: {
      type: String,
      default: '',
    },
    opacity: {
      type: [String, Number],
      default: '1',
    },
    uppercaseFirstLetter: {
      type: Boolean,
      default: false,
    },
    spacing: {
      type: String,
      default: 'inherit',
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: '',
    },
    align: {
      type: String,
      default: 'left',
      validate: (value) => ['left', 'center', 'right'].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { attrs }) {
    const classes = computed(() => Object.keys(attrs).reduce((acc, item) => ({
      ...acc,
      [`text__${item}`]: true,
    }), {}));
    const colorTheme = computed(() => colors[props.theme] || props.color);

    const calculatedLineHeight = computed(() => ((Math.round(props.size?.replace('px', '') * props.lineHeight) + 'px') || '13px'));

    return {
      classes,
      colorTheme,
      calculatedLineHeight,
    };
  },
};
</script>

<style lang="scss" scoped>
.text {
  font-size: v-bind('size');
  font-weight: v-bind('weight');
  line-height: v-bind('calculatedLineHeight');
  color: v-bind('color');
  margin: v-bind('mt') v-bind('mr') v-bind('mb') v-bind('ml');
  padding: v-bind('pt') v-bind('pr') v-bind('pb') v-bind('pl');
  opacity: v-bind('opacity');
  letter-spacing: v-bind('spacing');
  text-align: v-bind('align');

  &__uppercase-first::first-letter {
    text-transform: uppercase;
  }
  &__muted {
    color: var(--color-black);
    opacity: 0.5;
  }
  &__uppercase {
    text-transform: uppercase;
  }
  &__lowercase {
    text-transform: lowercase;
  }
}

.is-block {
  display: inline-block;
}
</style>
