import { computed, onUnmounted, watch } from 'vue';
import { io } from 'socket.io-client';

import { IS_ONION, BASE_URL } from '@/composables/useApp';
import { useRoute } from '@/router';

export const useSocket = () => {
  let SOCKET_URL = `wss://sync.${process.env.VUE_APP_BASE_URL}`;

  if (process.env.VUE_APP_BASE_URL === 'test.switch.staging.frl') {
    SOCKET_URL = `wss://sync.test.${BASE_URL}`;
  }
  if (IS_ONION) {
    SOCKET_URL = `ws://sync.${BASE_URL}`;
  }

  const socket = io(SOCKET_URL, { transports: ['websocket'] });

  const addSocketListener = (eventName, callback) => {
    const listener = (info) => callback(info);

    socket.on(eventName, listener);
    const route = useRoute();
    watch(computed(() => route?.name), () => {
      socket.disconnect(eventName);
    });

    onUnmounted(() => {
      socket.disconnect(eventName);
    });
  };

  return {
    socket,
    addSocketListener,
  };
};
