<template>
  <ContainerWrapper
    v-click-outside="onInputBlur"
    bordered
    box-shadow
    variant="label"
    class="input"
    :class="{
      input__error: error,
      input__disabled: disabled,
      'input__read-only': readOnly,
      'input__hide-shadow': hideShadow || readOnly,
      'input-container-focused': isFocused || modelValue
    }"
    :style="computedStyles"
    @click="onInputFocus"
  >
    <div
      v-if="$slots.prepend"
      class="input__prepend"
    >
      <slot name="prepend" />
    </div>
    <div class="input__body">
      <span
        class="input__body-label"
        :class="{
          'input__body-label_focused': isFocused || modelValue
        }"
      >{{ label }}</span>
      <span
        class="input__body-label"
        :style="{
          position: 'relative',
          opacity: 0
        }"
      >{{ label }}</span>
      <div class="input__body__field">
        <span
          v-if="showPlaceholder && !useNativePlaceholder"
          class="input__body__field-placeholder"
          :style="placeholderStyles"
        >{{ placeholder }}</span>
        <slot>
          <input
            ref="input"
            v-model="inputValue"
            :placeholder="useNativePlaceholder ? placeholder : ''"
            :onselectstart="userSelectionDisabled ? 'return false;' : ''"
            :onmousedown="userSelectionDisabled ? 'return false;' : ''"
            class="input__body__field-input"
            :class="{
              'selection-disable': userSelectionDisabled,
              'is-centered': alignCenter,
            }"
            :readonly="readOnly"
            :disabled="disabled"
            :type="type === 'number' ? 'text' : type"
            :maxlength="maxLength"
            @input="onInput"
            @keydown.enter="onEnterPress"
          >
        </slot>
      </div>
    </div>
    <div
      v-if="$slots.append"
      class="input__append"
    >
      <slot name="append" />
    </div>
    <div
      v-if="errorText && error"
      class="input__error-text"
    >
      {{ errorText }}
    </div>
  </ContainerWrapper>
</template>

<script>
import { computed, ref } from 'vue';
import ContainerWrapper from '@/components/Containers/ContainerWrapper.vue';
import clickOutside from '@/directives/clickOutside';

export default {
  name: 'AppInput',
  directives: {
    clickOutside,
  },
  components: { ContainerWrapper },
  inheritAttrs: true,
  props: {
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    useNativePlaceholder: {
      type: Boolean,
      default: false,
    },
    placeholderStyles: {
      type: Object,
      default: () => ({}),
    },
    errorText: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    fullOpacity: {
      type: [Number, String],
      default: 1,
    },
    hideShadow: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    userSelectionDisabled: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: [Number, String],
      default: Infinity,
    },
    alignCenter: {
      type: Boolean,
      default: false,
    },
    inputFontSize: {
      type: String,
      default: '16px',
    },
    inputLineHeight: {
      type: String,
      default: '16px',
    },
  },
  emits: ['update:modelValue', 'blur', 'focus', 'enter-press'],
  setup(props, { emit }) {
    const input = ref();
    const isFocused = ref(false);
    const inputValue = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit('update:modelValue', val);
      },
    });
    const showPlaceholder = computed(() => !inputValue.value);

    const onInputFocus = () => {
      if (isFocused.value) {
        return;
      }
      isFocused.value = true;
      emit('focus');
      if (!input.value) {
        return;
      }
      input.value.focus();
    };
    const onInputBlur = () => {
      if (!isFocused.value) {
        return;
      }
      isFocused.value = false;
      emit('blur');
      if (!input.value) {
        return;
      }
      input.value.blur();
    };

    const computedStyles = computed(() => ({
      '--opacity': props.fullOpacity,
      borderColor: isFocused.value ? 'var(--color-primary)' : '#E6E6E6',
      '--input-font-size': props.inputFontSize,
      '--input-line-height': props.inputLineHeight,
    }));

    const onInput = () => {
      if (props.type === 'number') {
        inputValue.value = inputValue.value.replace(/[^0-9]/g, '');
      }
    };

    const onEnterPress = () => {
      emit('enter-press');
    };

    return {
      input,
      isFocused,
      inputValue,
      showPlaceholder,
      computedStyles,

      onInput,
      onInputBlur,
      onInputFocus,
      onEnterPress,
    };
  },
};
</script>

<style lang="scss">
.input {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  margin-bottom: 16px;
  background: var(--color-white);

  &__disabled {
    background-color: #F7F7F7 !important;
    border-color: #E6E6E6 !important;
  }

  &__body {
    position: relative;
    width: 100%;

    &-label {
      position: absolute;
      //@include position-align-y-center;
      display: block;

      font-size: 12px;
      line-height: 1.2;

      color: var(--color-black);
      opacity: 0.4;

      transition: all 200ms linear;

      &_focused {
        top: 0;
        transform: none;
      }
    }
    &__field {
      position: relative;
      width: 100%;

      &-input {
        width: 100%;
        padding: 0;
        border: none;
        outline: none;

        font-size: var(--input-font-size);
        line-height: var(--input-line-height);

        color: var(--color-black);
        background: transparent;

        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        &[type=number] {
          -moz-appearance: textfield;
        }
      }
      &-placeholder {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.4;
      }
    }
  }
  &__prepend {
    margin-right: 10px;
  }
  &__error {
    border: 1px solid map-get($colors, "error") !important;
    box-shadow: $boxShadowError;
    margin: 0 0px 20px 0px;

    &-text {
      position: absolute;
      top: calc(100% + 6px);
      left: 0;

      font-size: 12px;
      line-height: 1.2;
      color: map-get($colors, "error");
    }
  }
  & > div {
    opacity: var(--opacity);
  }
}
.input__read-only.input {
  background-color: #FAFAFA;

  &__body {
    &-field {
      &__opacity {
        opacity: 0.4;
      }
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(transparent 150px, white);
      }
    }
  }
}
.input__hide-shadow.input {
  box-shadow: none;
}

.selection-disable {
  user-select: none;
  caret-color: transparent;
}

.is-centered {
  text-align: center;
}
</style>
