import axios from 'axios';

import { useTemporaryStore } from '@/store/stores/useTemporaryStore';
import { FETCH_URL } from '@/composables/useApp';

import {
  errorResponseHandler,
  requestErrorHandler,
  requestHandler,
} from './interceptors';
import { axiosWrapper, headers } from './index';
import { fillCachedResponse } from './cachedDataStorage';
import { needsToBeCached, successHandler } from './helpers';

export const temporaryInstance = axios.create({
  baseURL: FETCH_URL,
  headers,
});

export const tempResponseHandler = (response) => {
  const { store } = useTemporaryStore();
  const accessToken = response?.data?.data?.access?.token;
  const refreshToken = response?.data?.data?.refresh?.token;

  if (accessToken) {
    store.updateTokens({ accessToken, refreshToken });
    temporaryInstance.defaults.headers.Authorization = accessToken ? `Bearer ${accessToken}` : null;
  }

  if (needsToBeCached(response.config)) {
    fillCachedResponse(response.config, response);
  }

  // TODO REMOVE AFTER REFRESH_TOKEN FUNCTIONALLITY IS FIXED
  if (accessToken) {
    store.$patch({
      tokens: [...store.tokens, { accessToken, refreshToken }],
    });
  }

  return response;
};

temporaryInstance.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => requestErrorHandler(error),
);

temporaryInstance.interceptors.response.use(
  (response) => tempResponseHandler(response),
  (error) => errorResponseHandler(error),
);

export const axiosTempWrapper = async (params) => {
  const result = await axiosWrapper({ ...params, axiosInstance: temporaryInstance });
  return result;
};

export const useTempFetch = () => {
  const { accessToken } = useTemporaryStore();

  if (accessToken.value) {
    temporaryInstance.defaults.headers.Authorization = accessToken.value ? `Bearer ${accessToken.value}` : null;
  }

  return {
    axios: temporaryInstance,
    axiosTempWrapper,
    successHandler,
  };
};
