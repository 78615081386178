import { ref } from 'vue';
import axios from 'axios';

import { i18n } from '@/plugins/localization';
import { useToken } from '@/composables/useToken';
import { FETCH_URL } from '@/composables/useApp';

import {
  clearAxiosCashedState,
  successHandler,
  updateAxiosToken,
} from './helpers';
import { axiosErrorHandler } from './axiosErrorHandler';
import {
  errorResponseHandler,
  requestErrorHandler,
  requestHandler,
  responseHandler,
} from './interceptors';

export const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const instance = axios.create({
  baseURL: FETCH_URL,
  headers,
});

const channel = new BroadcastChannel('auth');
channel.onmessage = (event) => {
  instance.defaults.headers.Authorization = event.data;
};

instance.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => requestErrorHandler(error),
);

instance.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorResponseHandler(error),
);

export const axiosWrapper = async ({
  type,
  url,
  params,
  isErrorNotification = true,
  successMessage = null,
  useRefreshToken = false,
  responseType = 'json',
  useLogoutHandlerWhenError = true,
  axiosInstance = instance,
  hasForceNotification = false,
  isDefaultErrorNotificationEnabled = true,
  headers: incomeHeaders,
}) => {
  try {
    const axiosHeaders = {
      ...headers,
      incomeHeaders,
      useLogoutHandlerWhenError,
    };

    const { refreshToken } = useToken();

    if (useRefreshToken) {
      updateAxiosToken(refreshToken.value);
    }

    const isLoading = ref(true);
    const result = await axiosInstance({
      responseType,
      url,
      method: type,
      [type === 'get' ? 'params' : 'data']: params,
      headers: axiosHeaders,
    });

    isLoading.value = false;

    const { data } = result.data;
    const isSuccess = result.data.success;
    if (isSuccess && successMessage) {
      successHandler(successMessage);
    }
    return {
      result, data, isSuccess, isLoading,
    };
  } catch (error) {
    if (isErrorNotification) {
      axiosErrorHandler({ error, hasForceNotification, isDefaultErrorNotificationEnabled });
    }

    const errorObject = { message: '', fields: [], error: {} };

    if (error.response?.status === 400) {
      errorObject.fields = error.response.data.errors;
      errorObject.message = error.response.data.message;
    }

    errorObject.error = error.response;

    return {
      isSuccess: false,
      isLoading: false,
      error: error.response,
      errorObject,
    };
  }
};

export const useFetch = () => {
  const { authToken } = useToken();
  const { t } = i18n.global;

  if (authToken.value) {
    updateAxiosToken(authToken.value);
  }

  return {
    axios: instance,
    axiosWrapper,
    successHandler,
    t,
  };
};

export {
  axiosErrorHandler,
  successHandler,
  clearAxiosCashedState,
};
