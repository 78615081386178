import { computed } from 'vue';

import { isJWT } from '@/utils/functions';
import { useStore } from '@/store';
import { APP_ROLES } from '@/common/data';
import { updateAxiosInstanceToken } from '@/composables/useFetch/helpers';

export const useToken = (initialToken = null) => {
  const store = useStore();

  const authToken = computed(() => store.authToken);
  const refreshToken = computed(() => store.refreshToken);

  const token = computed(() => initialToken || authToken.value);

  const hasNoToken = computed(() => token.value === null || !isJWT(authToken.value));

  const userFromToken = computed(() => (hasNoToken.value
    ? {}
    : JSON.parse(atob(token.value.split('.')[1]))));

  const tokenRole = computed(() => userFromToken.value?.tokenRole || userFromToken.value?.role);
  const merchantId = computed(() => userFromToken.value?.id);

  const isMerchantRole = computed(() => tokenRole.value === APP_ROLES.MERCHANT);
  const isAdminRole = computed(() => [APP_ROLES.ADMIN, APP_ROLES.SUPER_ADMIN].includes(tokenRole.value));
  const isSuperAdminRole = computed(() => tokenRole.value === APP_ROLES.SUPER_ADMIN);

  const updateTokenInStorage = (tokens) => {
    store.$patch({
      authToken: tokens.accessToken,
    });
    updateAxiosInstanceToken(tokens.accessToken);

    if (tokens.refreshToken) {
      store.$patch({
        refreshToken: tokens.refreshToken,
      });
    }
  };

  return {
    authToken,
    refreshToken,
    tokenRole,
    merchantId,
    updateTokenInStorage,
    user: userFromToken,

    isAdminRole,
    isSuperAdminRole,
    isMerchantRole,
  };
};
