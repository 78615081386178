import { onBeforeMount, onBeforeUnmount } from 'vue';

export function useKeyboardEvent(keycode, func) {
  onBeforeMount(() => {
    window.addEventListener('keydown', (e) => {
      onAction(e);
    });
  });

  onBeforeUnmount(() => {
    window.removeEventListener('keydown', onAction);
  });

  const onAction = (e) => {
    if (e.keyCode === keycode) {
      func();
    }
  };
}

export const keys = Object.freeze({
  ENTER: 13,
  ESCAPE: 27,
  BACKQUOTE: 192,
  BACKSPACE: 8,
});
