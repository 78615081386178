import { onBeforeUnmount, ref } from 'vue';
import mitt from 'mitt';

export const emitter = mitt();

export const useBus = () => {
  const eventName = ref('');

  let fn = null;
  const emitHandler = (event, callback) => {
    eventName.value = event;
    fn = callback;
    emitter.on(event, (...params) => fn.apply(params));
  };

  onBeforeUnmount(() => {
    emitter.off(eventName.value);
  });

  return { emitHandler };
};

/** HOW TO USE

const { emitHandler } = useBus();
emitHandler(eventName, () => {
  any code here
});

*/
