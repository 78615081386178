import { computed } from 'vue';
import { storeToRefs } from 'pinia';

import { useStore } from '@/store';
import { accountTypes as planTypes } from '@/common/data';

export const useUser = () => {
  const store = useStore();
  const {
    user,
    wallets,
    notifications,
  } = storeToRefs(store);
  const companyName = computed(() => user.value?.companyName || user.value?.username);
  const email = computed(() => user.value.email);
  const userInfo = computed(() => user.value);
  const tokenRole = computed(() => user.value.role);
  const accountTypes = computed(() => planTypes);
  const currentPlan = computed(() => accountTypes.value[user.value?.subscription?.plan?.toLowerCase()]);
  const isPlanFree = computed(() => user.value?.subscription?.plan === 'FREE');
  const isPlanCancelled = computed(() => user.value?.subscription?.isCancelled);
  const profileAvatar = computed(() => store.profileAvatar);

  // login part
  const { uid, authToken } = storeToRefs(store);
  const role = computed(() => user.value.role);

  const isAvatarExists = computed(() => Boolean(user.value?.profileAvatar && user.value?.profileOriginalAvatar));

  return {
    companyName,
    email,
    user: userInfo,
    tokenRole,
    profileAvatar,
    wallets,
    notifications,

    accountTypes,
    currentPlan,
    isAvatarExists,
    isPlanFree,
    isPlanCancelled,

    // login part
    uid,
    role,
    authToken,
  };
};
