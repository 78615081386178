import { computed, ref } from 'vue';

import { fullCoinsArray, coinsSortWeight } from '@/common/data';
import { storeToRefs, useStore } from '@/store';

export const currentTab = ref('start');
export const useWallet = () => {
  const store = useStore();
  const { walletData } = storeToRefs(store);

  const publicPayments = computed(() => walletData.value?.publicAddress);
  const nonCustodialWallets = computed(() => walletData.value?.nonCustodialWallets);
  const walletCreationDate = computed(() => walletData.value?.walletCreationDate);
  const walletType = computed(() => walletData.value?.walletType);

  const isWalletConnected = computed(() => walletData.value.isWalletConnected);
  const isPublicPaymentsEnabled = computed(() => publicPayments.value?.isEnabled);

  return {
    publicPayments,
    walletCreationDate,
    nonCustodialWallets,
    walletType,
    isWalletConnected,
    isPublicPaymentsEnabled,
  };
};

export function convertNestedArrayToFlatObject(arr) {
  return arr.reduce((obj, item) => {
    if (Array.isArray(item.child)) {
      item.child.forEach((child) => {
        obj[child.coin] = child.enabled;
      });
    } else {
      obj[item.coin] = item.enabled;
    }
    return { ...obj, ...item };
  }, {});
}

export const processIncomingWalletData = (rawWallets) => {
  const processedWallets = rawWallets.reduce((newArr, wallet) => {
    if (Array.isArray(wallet.child)) {
      wallet.child.forEach((child) => {
        newArr.push({
          ...child,
          primaryAddress: wallet.primaryAddress,
          generatedAt: wallet.generatedAt,
          importedAt: wallet.importedAt,
        });
      });
    } else {
      delete wallet.child;
      newArr.push(wallet);
    }

    return newArr;
  }, []);

  return processedWallets;
};

export const postProcessWallets = ({ wallets, walletsAvailability }) => fullCoinsArray
  .map((coin) => {
    const selectedCoin = wallets?.find((el) => el.coin === coin.value);
    if (!selectedCoin) {
      return { xpub: null };
    }

    const isDisabledByAdmin = !walletsAvailability[coin.value];

    return {
      ...selectedCoin,
      ...coin,
      isDisabledByAdmin,
    };
  })
  .filter((coin) => coin.xpub || coin.pubVkey || coin.primaryAddress)
  .sort((a, b) => coinsSortWeight[a.coin] - coinsSortWeight[b.coin]);
