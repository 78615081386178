import { computed } from 'vue';
import { storeToRefs } from 'pinia';

import { timeFormats } from '@/common/data';
import { useAdminStore } from '@/store';

export const useAdmin = () => {
  const store = useAdminStore();
  const { admin, settings } = storeToRefs(store);

  const dateFormat = computed(() => admin.value?.dateFormat);
  const timeFormat = computed(() => admin.value?.timeFormat || timeFormats.value[0].value);
  const numberFormat = computed(() => admin.value?.numberFormat);
  const timezoneCode = computed(() => admin.value?.timezone?.code);
  const timezoneTime = computed(() => admin.value?.timezone?.time);
  const email = computed(() => admin.value?.email);
  const role = computed(() => admin.value?.role);

  const profileAvatar = computed(() => admin.value?.profilePicture?.cropUrl && `${admin.value?.profilePicture?.cropUrl}?${Math.random()}`);

  const adminSettings = computed(() => settings?.value);

  const registeredDate = computed(() => admin.value?.twoFaUpdatedAt);

  const profileId = computed(() => admin.value?.username);

  const merchantHistoryStartDate = computed(() => admin.value?.merchantHistoryStartDate);

  return {
    admin,
    dateFormat,
    timeFormat,
    numberFormat,
    timezoneCode,
    timezoneTime,

    profileAvatar,
    email,
    role,
    adminSettings,

    registeredDate,
    profileId,
    merchantHistoryStartDate,
  };
};
