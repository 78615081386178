import { refreshTokens } from '@/api/auth';
import { sequalize } from '@/utils/functions';
import { useNotifications } from '@/composables/useNotifications';
import { useToken } from '@/composables/useToken';
import { coloredLog } from '@/utils/coloredLog';
import { i18n } from '@/plugins/localization';
import { useProfile } from '@/composables/useProfile';

import { instance } from './index';
import { clearCachedResponses } from './cachedDataStorage';

const ENDPOINTS_NEEDS_TO_BE_CACHED = [];
export const needsToBeCached = (request) => ENDPOINTS_NEEDS_TO_BE_CACHED.includes(request.url);

const channel = new BroadcastChannel('auth');
export const updateAxiosInstanceToken = (token) => {
  const newToken = `Bearer ${token}`;
  instance.defaults.headers.Authorization = token ? newToken : null;
  channel.postMessage(newToken);
};

export const updateAxiosToken = (token) => {
  const newToken = `Bearer ${token}`;
  instance.defaults.headers.Authorization = newToken;
};

const refreshTokensHandler = async () => {
  const { updateTokenInStorage } = useToken();
  const { isSuccess, accessToken, refreshToken } = await refreshTokens();

  if (isSuccess) {
    updateTokenInStorage({ accessToken, refreshToken });
    coloredLog('SESSION WAS UPDATED');
  } else {
    console.error('SESSION WASNT UPDATED');
  }
  return isSuccess;
};

export const debouncedRefreshTokensHandler = sequalize(refreshTokensHandler, 500);

export const clearAxiosCashedState = () => {
  clearCachedResponses();
};

export const successHandler = (text = '') => {
  const { addNotification } = useNotifications();
  addNotification({ text, config: { color: 'success' } });
};

export const getTimezoneOffset = () => {
  let offset = new Date().getTimezoneOffset();
  const sign = offset < 0 ? '+' : '-';
  offset = Math.abs(offset);
  return `${sign}${offset / 60 || 0}`;
};

export const getLocalizedErrorMessage = (errorMessage, params = {}) => {
  const { t } = i18n.global;
  const { isAdminZone } = useProfile();

  let fallbackErrorMessage = t('errors.UNKNOWN_ERROR');

  if (isAdminZone.value) {
    fallbackErrorMessage = errorMessage;
  }

  const translation = t(`errors.${errorMessage}`, params);
  const localizedErrorMessage = translation === `errors.${errorMessage}` ? fallbackErrorMessage : translation;

  return localizedErrorMessage;
};
