import { truncateLongString } from '@/utils/functions';

const ONION_URL = 'switchxxxjtcukf3jjvczui7nq7spl73aikm4jop7l3opppk7ylt5qyd.onion';

const IS_ONION = window.location.hostname.endsWith('.onion');
const BASE_URL = IS_ONION ? ONION_URL : process.env.VUE_APP_BASE_URL;
const FETCH_URL = IS_ONION ? `http://api.${ONION_URL}` : `https://api.${process.env.VUE_APP_BASE_URL}`;

export const useApp = () => {
  window.truncateLongString = truncateLongString;

  const truncatedOnionUrl = truncateLongString(ONION_URL, {
    threshold: 5, startLength: 6, endLength: 34, dots: '....',
  });

  const truncatedShortOnionUrl = truncateLongString(ONION_URL, {
    threshold: 5, startLength: 9, endLength: 5, dots: '...',
  });

  return {
    truncatedOnionUrl, truncatedShortOnionUrl, IS_ONION, ONION_URL, BASE_URL, FETCH_URL,
  };
};

export {
  IS_ONION, ONION_URL, BASE_URL, FETCH_URL,
};
