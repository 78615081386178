import { computed } from 'vue';
import dayjs from 'dayjs';

import { useStore } from '@/store';
import { languages as rawLanguages } from '@/common/data';
import { i18n } from '@/plugins/localization';
import { useProfile } from '@/composables/useProfile';

export function useLang() {
  const store = useStore();
  const { isAdminZone } = useProfile();

  const language = computed(() => (isAdminZone.value ? 'en' : store.language));

  const languages = computed(() => (isAdminZone.value ? [rawLanguages[0]] : rawLanguages));

  const languageObject = computed(() => languages.value.find((el) => el.value === language.value));

  const setLanguage = (lang) => {
    const isBlocked = 'soon' in languages.value.find((el) => el.value === lang);

    if (lang && !isBlocked) {
      store.$patch({ language: lang });
      i18n.global.locale.value = lang;
      dayjs.locale(lang);
    }
  };

  return {
    language,
    languageObject,

    languages,
    setLanguage,
  };
}

export default useLang;
