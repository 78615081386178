import {
  computed, onBeforeUnmount, reactive, toRefs,
} from 'vue';

import { emitter } from '@/composables/useBus';
import { useFetch } from '@/api';
import { useTablesStore } from '@/store';

const instances = {};

function createInstance(name) {
  if (!instances[name]) {
    instances[name] = reactive({
      page: 1,
      perPage: 25,
      search: '',
      status: null,

      isLoading: false,
      isLoaded: false,
      items: [],
      selectedRows: [],
      filters: {},

      pageCount: 0,
      totalCount: 0,
      // needs to determine is it first call or not to have more clean animation
      counter: 0,

      sortBy: null,
      orderBy: null,

      computedParams: {},
      tableFiltersSchema: [],
    });
  }
}

function getSortParams(instanceName, sortBy) {
  if (instances[instanceName].sortBy !== sortBy) {
    instances[instanceName].orderBy = null;
  }
  instances[instanceName].sortBy = sortBy ?? instances[instanceName].sortBy;

  if (sortBy && instances[instanceName].orderBy === null) {
    instances[instanceName].orderBy = 'DESC';
    return;
  }
  if (sortBy && instances[instanceName].orderBy === 'DESC') {
    instances[instanceName].orderBy = 'ASC';
    return;
  }
  if (sortBy && instances[instanceName].orderBy === 'ASC') {
    instances[instanceName].sortBy = null;
    instances[instanceName].orderBy = null;
  }
}

function fillInstanceParams(name, params) {
  instances[name].page = params.page ?? instances[name].page;
  instances[name].perPage = params.perPage ?? instances[name].perPage;
  instances[name].search = params.search ?? instances[name].search;
  instances[name].status = params.status ?? instances[name].status;
  instances[name].filters = params.filters ?? instances[name].filters;

  if (params.sortBy) {
    getSortParams(name, params.sortBy);
  }

  return {
    page: instances[name].page,
    perPage: instances[name].perPage,
    search: instances[name].search,
    status: instances[name].status,
    sortBy: instances[name].sortBy,
    orderBy: instances[name].orderBy,
    ...instances[name].filters,
  };
}

export const clearInstances = () => {
  Object.keys(instances).forEach((prop) => {
    delete instances[prop];
  });
};

export const resetDateFilters = () => {
  const tableStore = useTablesStore();
  tableStore.resetDateFilters();
  clearInstances();
};

export const subscribeToUpdateEmmiter = (eventName, getData, fn) => {
  if (eventName) {
    emitter.on(eventName, () => {
      getData({ isFreshResponse: true });
      if (fn) {
        fn();
      }
    });

    onBeforeUnmount(() => {
      emitter.off(eventName);
    });
  }
};

const setInstanceParams = (instance, moduleName) => {
  const tableStore = useTablesStore();
  const table = tableStore.getCurrentTable({ moduleName });
  const itemsPerPage = table?.itemsPerPage;
  const status = table?.status;

  if (itemsPerPage) {
    instance.perPage = itemsPerPage;
  }
  if (status) {
    instance.status = status;
  }
};

const getParamsFromStore = ({ instance, opts }) => {
  const tableStore = useTablesStore();
  const table = tableStore.getCurrentTable({ moduleName: opts.moduleName });

  const params = {};

  instance.tableFiltersSchema.forEach((f) => {
    const isArrayFields = Array.isArray(f.field);
    if (isArrayFields) {
      f.field.forEach((arrField) => {
        if (table && table[arrField]) {
          params[arrField] = table[arrField];
        }
      });
    }

    if (!isArrayFields && table && table[f.field]) {
      params[f.field] = table[f.field];
    }
  });

  return params;
};

export function usePagination(nameOrPath, opts = {}) {
  const { axiosWrapper } = useFetch();

  createInstance(nameOrPath);
  const instance = instances[nameOrPath];

  setInstanceParams(instance, opts.moduleName);

  if (opts.filters) {
    instance.tableFiltersSchema = opts.filters;
  }

  const getData = async (inputParams = {}) => {
    try {
      instance.isLoading = true;
      if (instance.counter === 0) {
        instance.isLoaded = false;
      }
      const params = fillInstanceParams(nameOrPath, inputParams);
      params.isFreshResponse = inputParams.isFreshResponse ?? null;
      params.shouldResetRequestState = inputParams.shouldResetRequestState ?? null;

      const paramsFromStore = getParamsFromStore({ instance, opts });
      Object.assign(params, paramsFromStore);
      if (params.status === null || params.status === 'ALL') {
        delete params.status;
      }

      const { result: response } = await axiosWrapper({ type: 'get', url: nameOrPath, params });

      instance.computedParams = params;

      if (response?.data?.success) {
        const result = response.data?.data;
        instance.totalCount = result.totalCount;
        instance.pageCount = result.pageCount;

        const processedResult = result.data
          .map((el) => {
            // eslint-disable-next-line no-underscore-dangle
            const sysmetId = el._id;
            const rest = {};
            if (el.client?.clientEmail) {
              rest.clientEmail = el.client.clientEmail;
            }
            if (el.client?.clientName) {
              rest.clientName = el.client.clientName;
            }
            if (el.adminId && !el.id) {
              rest.id = el.adminId;
            }
            if (sysmetId && !el.id) {
              rest.id = sysmetId;
            }
            return {
              ...el,
              ...rest,
            };
          })
          .splice(0, params.perPage);

        if (!(processedResult.length === 0 && instance.items.length === 0)) {
          instance.items = processedResult;
        }
        return instance.items;
      }
      throw Error('Error');
    } catch (error) {
      console.error(error);
      return error;
    } finally {
      instance.isLoading = false;
      instance.isLoaded = true;
      instance.counter++;
    }
  };

  const clearFilterParams = (filters) => {
    emitter.emit('clear-filters', filters);
    filters.value.forEach((f) => {
      if (instance.filters[f[0]]) {
        instance.filters[f[0]] = null;
        return;
      }
      instance[f[0]] = null;
    });
  };

  if (opts.eventName) {
    subscribeToUpdateEmmiter(opts.eventName, getData);
  }

  return {
    ...toRefs(instance),
    items: computed(() => instance.items),

    getData,
    clearFilterParams,
  };
}
