import { onMounted, ref } from 'vue';

export const useSlots = ({ slots, slotName = 'default' }) => {
  const hasSlotData = ref(false);

  onMounted(() => {
    if (slots[slotName]) {
      hasSlotData.value = true;
    }
  });

  return {
    hasSlotData,
  };
};
