import { ref, onMounted, onBeforeUnmount } from 'vue';

export const useTabNavigation = () => {
  const element = ref(null);

  const isFocusedByKeyboard = ref(false);
  const onKeyup = (event) => {
    if (event.code === 'Tab') {
      isFocusedByKeyboard.value = true;
    }
  };

  onMounted(() => {
    const ELEMENT = element.value?.input || element.value?.textarea || element.value?.reference?.input;
    if (ELEMENT) {
      ELEMENT.addEventListener('keyup', onKeyup);
    }
  });

  onBeforeUnmount(() => {
    const ELEMENT = element.value?.input || element.value?.textarea || element.value?.reference?.input;
    if (ELEMENT) {
      ELEMENT.removeEventListener('keyup', onKeyup);
    }
  });

  return { element, isFocusedByKeyboard };
};

// @include focus-visible-style;
