import { i18n } from '@/plugins/localization';
import { useNotifications } from '@/composables/useNotifications';
import {
  pushFileErrorNotification,
  pushNetworkErrorNotification,
} from '@/utils/functions';

import { getLocalizedErrorMessage } from './helpers';

export const axiosErrorHandler = ({
  error, message, hasForceNotification, isDefaultErrorNotificationEnabled,
}) => {
  const { addNotification } = useNotifications();
  const axiosErrorMessage = message || error?.response?.data?.message;
  const errorMessage = axiosErrorMessage || 'Error';

  if (error.code === 'ERR_NETWORK') {
    pushNetworkErrorNotification();
  }

  const localizedErrorMessage = getLocalizedErrorMessage(errorMessage);

  const codeErrors = [400, 401, 405, 429, 503];

  if (isDefaultErrorNotificationEnabled) {
    if (!codeErrors.includes(error.response?.status) || hasForceNotification) {
      addNotification({ text: localizedErrorMessage, config: { color: 'error' } });
    }

    if (error.response?.status === 405 && error.response?.data?.message === 'INSUFFICIENT_BALANCE_FOR_THE_OPERATION') {
      addNotification({
        text: localizedErrorMessage,
        config: { color: 'error', duration: 5000 },
      });
    }

    if (error.response?.status === 429 && error.response?.data?.message === 'REQUEST_BLOCKED') {
      addNotification({
        text: localizedErrorMessage,
        config: { color: 'warning', duration: 5000 },
      });
    }

    if (error.response?.status === 400 && error.response?.data?.message === 'TECHNICAL_ERROR') {
      addNotification({
        text: localizedErrorMessage,
        config: { color: 'error', duration: 5000 },
      });
    }

    if (error.response?.status === 404 && error.response.data instanceof Blob) {
      pushFileErrorNotification(error);
    }
  }

  if (error.response?.status === 500) {
    const { t } = i18n.global;
    addNotification({ text: t('merchantDashboard.notification.code500'), config: { color: 'error' } });
  }
};
