<template>
  <component :is="layout" :class="theme" />
</template>

<script>
import { computed, defineAsyncComponent } from 'vue';

import { useRoute } from '@/router';
import { useToken } from '@/composables/useToken';

export default {
  name: 'AppLayout',
  components: {
    Dashboard: defineAsyncComponent(() => import('./MainLayout')),
    AdminAuth: defineAsyncComponent(() => import('./AdminAuth.vue')),
    EmptyLayout: defineAsyncComponent(() => import('./EmptyLayout.vue')),
    LandingLayout: defineAsyncComponent(() => import('./LandingLayout')),
    PaymentLayout: defineAsyncComponent(() => import('./PaymentLayout')),
    SignupLayout: defineAsyncComponent(() => import('./SignupLayout')),
    ClientLayout: defineAsyncComponent(() => import('./ClientLayout.vue')),
  },
  setup() {
    const route = useRoute();
    const layout = computed(() => route.meta.layout);
    const { isAdminRole } = useToken();

    const theme = computed(() => {
      if (isAdminRole.value) {
        return 'theme-admin';
      }
      return '';
    });

    return { layout, theme };
  },
};
</script>
