import { computed } from 'vue';

export const useModel = (props, emit, options = {}) => {
  const formatter = options.formatter || ((val) => val);
  const prop = options.prop || 'modelValue';

  const value = computed({
    get: () => formatter(props[prop]),
    set(val) {
      emit(`update:${prop}`, formatter(val));
    },
  });
  return {
    value,
  };
};
