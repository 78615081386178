export const clickOutside = {
  beforeMount(el, binding) {
    const func = binding.value?.handler || binding.value;
    const relatedElements = binding.value?.related || [];
    el.clickOutsideEvent = (event) => {
      let isOutside = true;
      relatedElements.forEach((item) => {
        if (item === event.target || item.contains(event.target) || event.path.includes(item)) {
          isOutside = false;
        }
      });
      if (el === event.target || el.contains(event.target)) {
        isOutside = false;
      }
      if (isOutside) {
        func(event, el);
      }
    };
    document.body.addEventListener('mousedown', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('mousedown', el.clickOutsideEvent);
  },
};

export default clickOutside;
