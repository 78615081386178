import { computed } from 'vue';
import { storeToRefs } from 'pinia';

import { dateFormats, timeFormats, numberFormats } from '@/common/data';
import { useStore } from '@/store';

export const useSettings = () => {
  const store = useStore();
  const { language: lang, settings } = storeToRefs(store);

  if (settings.value?.language) {
    lang.value = settings.value?.language;
  }

  const dateFormat = computed(() => {
    if (settings.value?.dateFormat === null) {
      return dateFormats.value[0].value;
    }

    return settings.value?.dateFormat;
  });

  const timeFormat = computed(() => {
    if (!settings.value?.timeFormat) {
      return timeFormats.value[0].value;
    }

    return settings.value?.timeFormat;
  });

  const numberFormat = computed(() => {
    const value = settings.value?.numberFormat;

    if (value === null) {
      return numberFormats.value[0].value;
    }

    return value;
  });

  const language = computed(() => lang.value);
  const timezoneCode = computed(() => settings.value?.timezone?.code);
  const timezoneTime = computed(() => settings.value?.timezone?.time);

  const profileId = computed(() => settings.value?.merchantId);
  const registeredDate = computed(() => settings.value?.createdAt);

  return {
    language,
    dateFormat,
    timeFormat,
    timezoneCode,
    timezoneTime,
    numberFormat,
    profileId,

    settings,
    registeredDate,
  };
};
