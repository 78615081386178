import { computed } from 'vue';
import dayjs from 'dayjs';

import { router } from '@/router';

import { useAdmin } from './useAdmin';
import { useSettings } from './useSettings';
import { useToken } from './useToken';

export const useProfile = () => {
  const { isAdminRole } = useToken();
  const isAdminZone = computed(() => (isAdminRole.value || router.currentRoute?.value?.meta?.layout === 'AdminAuth'));

  const {
    timezoneTime,
    dateFormat,
    timeFormat,
    timezoneCode,
    profileId,
    numberFormat,
    registeredDate,
  } = isAdminRole.value ? useAdmin() : useSettings();

  const dateTimeFormat = computed(() => `${dateFormat.value} (${timeFormat.value})`);

  const currentDate = computed(() => (timezoneCode.value
    ? dayjs().tz(timezoneCode.value).format(dateFormat.value)
    : dayjs().format(dateFormat.value)));

  const currentDateTime = computed(() => {
    const format = `${dateFormat.value} ${timeFormat.value}`;
    return dayjs().tz(timezoneCode.value).format(format);
  });

  const { merchantHistoryStartDate } = useAdmin();

  const startDatePeriodDate = computed(() => (isAdminRole.value ? merchantHistoryStartDate.value : registeredDate.value));

  return {
    isAdminZone,
    timezoneTime,
    dateFormat,
    timeFormat,
    dateTimeFormat,
    registeredDate,
    timezoneCode,
    profileId,

    currentDate,
    currentDateTime,
    numberFormat,

    startDatePeriodDate,
  };
};
