import { ref } from 'vue';

import { useFetch } from '@/api';

export const useImage = () => {
  const imageSrc = ref('');

  const getImageUrl = async (imageUrl, callback) => {
    const { axios } = useFetch();
    const URL = imageUrl.replace('/api', '');
    try {
      const response = await axios.get(URL, {
        responseType: 'blob',
      });
      const reader = new window.FileReader();
      reader.readAsDataURL(response.data);
      reader.onload = () => {
        imageSrc.value = reader.result;
        if (callback) {
          callback(reader.result);
        }
      };
    } catch (error) {
      imageSrc.value = undefined;
    }
  };

  return {
    imageSrc,
    getImageUrl,
  };
};
